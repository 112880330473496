/** @format */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logoImg, userRounded, bellIcon } from "../../../assest";
import { UserPanelBar } from "../../../components/Modals/Modals";
import "./Navbar.css";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { getApi, postApi, putApi } from "../../../Repository/Api";
import endPoints from "../../../Repository/apiConfig";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const [profile, setProfile] = useState({ findReview: [] });
  const userType = localStorage.getItem("user-type");
  const fetchHandler = () => {
    getApi(endPoints.getAdwizorProfile, {
      setResponse: setProfile,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);
  const updateStatus = () => {
    if (profile?.data?.liveStatus === true) {
      putApi(
        endPoints.adwizor.completeMeeting(profile?.data?.currentMeetingRoom),
        {},
        {
          successMsg: "Status Updated !",
          additionalFunctions: [fetchHandler],
        }
      );
    } else {
      postApi(
        endPoints.adwizor.goLive,
        {},
        {
          successMsg: "Status Updated !",
          additionalFunctions: [fetchHandler],
        }
      );
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Status</Popover.Header>
      <Popover.Body>
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Status"
          checked={profile?.data?.liveStatus}
          onClick={updateStatus}
          style={{ cursor: "pointer" }}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <UserPanelBar show={open} handleClose={() => setOpen(false)} />
      <div className="header user-panel-nav">
        <Link to={"/"}>
          <img src={logoImg} alt="" className="logo" />
        </Link>

        <div>
          {userType === "advisor" ? (
            <>
              <Link to="/adwizor-panel/message">
                <img src={bellIcon} alt="" />
              </Link>
              <Link to="/adwizor-panel/my-profile">
                <OverlayTrigger
                  trigger={["click"]}
                  placement="bottom"
                  overlay={popover}
                  show={showPopover}
                  onToggle={(nextShow) => setShowPopover(nextShow)}
                >
                  <img
                    src={userRounded}
                    alt=""
                    onMouseEnter={() => setShowPopover(true)}
                    // onMouseLeave={() => setShowPopover(false)}
                  />
                </OverlayTrigger>
              </Link>
            </>
          ) : (
            <>
              <Link to="/user-dashboard/my-messages">
                <img src={bellIcon} alt="" />
              </Link>
              <Link to="/user-dashboard/profile">
                <img src={userRounded} alt="" />
              </Link>
            </>
          )}

          <i className="fa-solid fa-bars" onClick={() => setOpen(true)} />
        </div>
      </div>
    </>
  );
};

export default Navbar;
